import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "../components/row"
import Spacer from "../components/spacer"
import Arrow from "../components/arrow"

import l from "../components/layout.module.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Pagina niet gevonden" />
    <Row backgroundColor="#f2f2f2">
      <div
        style={{ paddingBottom: 0 }}
        className={[l.col1, l.title_section].join(" ")}
      >
        <h1 className={l.title}>Pagina niet gevonden :(</h1>
      </div>
      <Spacer>
        <Link>
          <Arrow rotation="180deg" fontSize="18px" fontWeight="bold">
            Terug naar homepage
          </Arrow>
        </Link>
      </Spacer>
    </Row>
  </Layout>
)

export default NotFoundPage
